
class BatchSyncModalController {
    time: string;
    titleMessage: string;
    title: string;
    message2: string;
  message3: string;

  constructor() {
        this.time = '02.00 AM';
        this.message2 = "For accurate batch reporting, please set the Batch Close time to match what is entered by the acquirer.";
        this.titleMessage = "NOTE: FOR REPORTING PURPOSES ONLY. THIS ENTRY WILL NOT IMPACT THE BATCH CLOSE TIME."
        this.message3 = "To adjust your Batch Close time, please reach out to your merchant services provider."
        this.title = "Sync Batch Times";

    }

}
angular.module('transactionsApp').component('batchSyncModal', {

    template: `
                <!-- Button trigger modal -->

                    <button type="button" class="btn btn-default no-border toggleBatchSync" data-toggle="modal" data-target="#exampleModal" tooltip-placement="right" uib-tooltip-html="'<div>Configure batch time sync</div>'" style="padding: 0.1em 0.5em;">
                        <!-- Gear Icon -->
                        <i class="pg-settings">
                        </i>
                    </button>

                <!-- Modal -->
                <div class="modal modal-background fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{$ctrl.title}}</h5>
                        <hr/>
                        <modal-alert title-message="$ctrl.titleMessage" message2="$ctrl.message2" message3="$ctrl.message3"></modal-alert>
                        </div>
                        <hr/>
                        <div class="modal-body">
                            <div>
                                <time-input></time-input>
                            </div>


                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="button" ng-click="$root.changeTime($root.tempTime)" class="btn btn-primary" data-dismiss="modal" >Save changes</button>
                        </div>
                    </div>
                    </div>
                </div>
            `,
    controller: BatchSyncModalController
});
