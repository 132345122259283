'use strict';
import moment from "moment-timezone";

var transactionsApp = angular.module('transactionsApp',
  [
    'angularjs.daterangepicker',
    'angularUtils.directives.dirPagination',
    'vesparny.fancyModal',
    'ngSanitize',
    'ngCookies',
    'ngRoute',
    'ui.bootstrap',
  ]).controller('transactionsCtrl', [
    function (){}]);


